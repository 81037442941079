import store from '@/store';
import Modules from "@/store/enums/Modules";
import TablePhysicalCountState from "../../model/PhysicalCountState";
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from 'vuex-module-decorators';
import PaginationRequest from '@/models/general/PaginationRequest';
import PhysicalInventoryRow from '@/models/physicalInventory/PhysicalInventoryRow';
import FiltersPhysicalInventory from '@/models/physicalInventory/filters/FiltersPhysicalInventory';
import OptionSelect from '@/models/shared/OptionSelect';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import PhysicalInventoryService from '@/core/services/physicalInventory/PhysicalInventoryService';
import WarehouseComboService from '@/core/services/werehouse/WarehouseComboService';

enum MutationsOwner { 
    SET_SELECT_ROW = "SET_SELECT_ROW"
    , RESET_SELECT_ROW = "RESET_SELECT_ROW"
}
const defaultRow = {
    id: ''
    , createdAt: ''
    , folio: ''
    , responsibleId: ''
    , status: ''
    , statusName: ''
    , warehouseId: ''
    , warehouseName: ''
} as PhysicalInventoryRow;
const defaultFilters = {
    warehouseId: ''
    , status: null
} as FiltersPhysicalInventory

@Module({ dynamic: true, store, namespaced: true, name: Modules.TablePhysicalCountModule })
class TablePhysicalCountModule extends VuexModule implements TablePhysicalCountState {
    rowSelected = defaultRow;
    selects = { warehouseOptions: [] as OptionSelect[] };
    records = [] as PhysicalInventoryRow[];
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    filters = defaultFilters;
    loading = false; 

    get getSelectedFolio(): string { 
        return this.rowSelected.folio;
    }
    get getSelectedRow(): PhysicalInventoryRow { 
        return this.rowSelected;
    }
    get getRecordsTable(): PhysicalInventoryRow[] { 
        return this.records;
    }
    get isInProcess(): boolean { 
        return (+this.rowSelected.status == 0); 
    }

    @Mutation
    [MutationsOwner.SET_SELECT_ROW](value: PhysicalInventoryRow | undefined) {
        if (value) { 
            this.rowSelected = value;
        }
    }
    @Mutation
    [MutationsOwner.RESET_SELECT_ROW](){
        this.rowSelected = defaultRow;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: PhysicalInventoryRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = defaultFilters;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersPhysicalInventory>) {      
        SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA]() {
         this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const resp = (await (service.getTable(this.filters, this.pagination)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as PhysicalInventoryRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
         }
        //const data = resp.data.map(r => { return {...r, statusName: getOptions().find(o => o.id == r.status)?.name ?? '' } })
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersPhysicalInventory>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    @MutationAction
    async getComboWarehouse(filterName: string) {
        //this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: true });
        const states = await serviceWarehouseCombo.searchCombo(filterName)
            /*.finally(() => {
                this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: false });
            });*/
        const selects = { ...this.selects }
        return { selects: { ...selects, warehouseOptions: states.data ?? [] } }
    }

    @Action
     async searchInfoSelected(id: string) {
         return service.getInfo(id)
             .then((reception) => { 
                 let isCorrect = false;
                if (reception) { 
                    isCorrect = true;
                    this.context.commit(MutationsOwner.SET_SELECT_ROW, reception.data ?? defaultRow);
                }
                 return isCorrect;
             })
    }
}

const service = new PhysicalInventoryService();
const serviceWarehouseCombo = new WarehouseComboService();

export default getModule(TablePhysicalCountModule);
