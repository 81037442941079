
export enum Mutations {
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_TABLE_DATA = "SET_TABLE_DATA"
    , SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS"
    , SET_FILTERS = "SET_FILTERS"
    , SET_PAGINATION = "SET_PAGINATION"
    , SET_PDF = "SET_PDF"
    , RESET_TABLE = "RESET_TABLE"
    , RESET_FILTERS = "RESET_FILTERS"
}