import SwalMessageService from "../SwalMessageService";
import NewPhysicalInventory from "@/models/physicalInventory/NewPhysicalInventory";
import { AxiosResponse } from "axios";
import BasicCatalogService from "../general/BasicCatalogService";
import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import PhysicalInventoryRow from "@/models/physicalInventory/PhysicalInventoryRow";
import ApiService from "../ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import FiltersPhysicalInventory from "@/models/physicalInventory/filters/FiltersPhysicalInventory";
import FiltersPhysicalInventoryDetails from "@/models/physicalInventory/filters/FiltersPhysicalInventoryDetails";

import { translate } from "@/core/plugins/i18n";
export default class PhysicalInventoryService extends BasicCatalogService<NewPhysicalInventory> {

    constructor() { 
        super("/Physicals", new SwalMessageService())
    }
    
    async getTable(filters: FiltersPhysicalInventory, pagination: PaginationRequest) { 
        return ApiService.get<PaginationResponse<PhysicalInventoryRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${pagination?.currentPage ?? 1}`
            + `&PageSize=${pagination?.pageSize ?? 10}`
            + (filters?.warehouseId ?? '' ? `&Criteria.WareHouseId=${filters?.warehouseId ?? ''}` : '')
            + (filters.status === Number(filters.status)  ? `&Criteria.Status=${filters.status}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getTableDetails(idFather: string, filters: FiltersPhysicalInventoryDetails, pagination: PaginationRequest) { 
        return ApiService.get<PaginationResponse<PhysicalInventoryRow>>(this.apiController, 
            `GetPagedListDetail?`
            + `CurrentPage=${pagination?.currentPage ?? 1}`
            + `&PageSize=${pagination?.pageSize ?? 10}`
            + `&Criteria.guid=${idFather}`
            + (filters?.filterName ?? '' ? `&Criteria.Name=${filters?.filterName ?? ''}` : '')
            + (filters?.folio ?? '' ? `&Criteria.Folio=${filters?.folio ?? ''}` : '')
            + (filters?.filterActive ?? '' ? `&Criteria.Active=${filters?.filterActive ?? ''}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async authorize(idFather: string) { 
        return ApiService.put(this.apiController + `/${idFather}/authorize`, {})
            .then(resp => {
                console.log(resp);
                return resp;
            });
    }

    async downloadLayout(idFather: string) { 
        return ApiService.dowloadFile(this.apiController,` ${idFather}/exportLayout`)
            .then(resp => {
                const blob = new Blob([resp.data], {
                    type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                })
                const url = window.URL.createObjectURL(blob);
                if (url) {
                    window.open(url);
                } else { 
                    this.messageService.error(translate('ERRORS.FILE_MISSING'));
                }
            });
    }

    async uploadFile(idFather: string, file: any): Promise<boolean> { 
        const fd = new FormData();
        fd.append('Layout',file)
        fd.append('PhysicalInventoryId',idFather)
        return ApiService.uploadFile(this.apiController, 'createdetail', fd)
            .then(resp => {
                return resp;
            });
    }
}