import { getMessageError, getMessageHeader } from "@/core/helpers/messageFromPulmeros";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import CrudService from "./interfaces/CrudService";
import { AxiosResponse } from "axios";
import { translate } from "@/core/plugins/i18n";
/**
 * Catalogo con los servicios basicos (CRUD)
 * 
 */
export default class BasicCatalogService<FormModel> implements GeneralService, CrudService<FormModel, FormModel> { 
    apiController: string;
    messageService: MessageService;

    /**
     * constructor
     * @param apiController controlador
     * @param messageService servicio de mensaje
     */
    constructor(apiController: string, messageService: MessageService){
        this.apiController = apiController;
        this.messageService = messageService;
    }
    async getInfo(id: string): Promise<AxiosResponse<FormModel>> {
        return  ApiService.get<FormModel>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async save(form: FormModel): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    async update(id: string, newInfo: FormModel): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
            , id
            , newInfo
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
}