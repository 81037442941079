/**
 * Asigna las propiedades de un objeto al store.
 * @param state estado donde se agregara los nuevo valores de las propiedades
 * @param values valores nuevos
 * @param isFilter indica que el funcionamiento sera de guardado de parametos de filtro
 */
const SaveValuesToStore = (state: any, values: any, isFilter = false) => {
    //obtenemos el nombre de la propiedades
    const propsToUpdate = Object.keys(values);
    for (let index = 0; index < propsToUpdate.length; index++) {
        const propName = propsToUpdate[index];
        if(propName in state || isFilter){//verificamos si existe la propiedad en el estado para agregarlo
            //pero si es filtro no la va a tener... entonces lo determinamos por la variable 'isFilter' si la agregara
            state[propName] = values[propName];
        }
    }
}

export { SaveValuesToStore }