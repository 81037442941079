import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SearchComboService from "./interfaces/SearchComboService";

/**
 * Clase por defecto de la busqueda de un combo
 */
export default class ComboSelectService<FormModel> implements SearchComboService<FormModel>, GeneralService{
    apiController: string;
    messageService: MessageService;

    /**
     * cosntructor
     * @param apiController controlador
     * @param messageService servicio de mensaje
     */
    constructor(apiController: string, messageService: MessageService){
        this.apiController = apiController;
        this.messageService = messageService;
    }

    searchCombo(filterName: string, extraParams: any = null): Promise<AxiosResponse<FormModel[]>> {
        let filtersQuery = '';
        let isFirstProp = true;

        if (filterName != '') { 
            filtersQuery = `?criteria=${filterName}`;
            isFirstProp = false;
        }
        if (extraParams != undefined && extraParams != null) { 
            const propsToUpdate = Object.keys(extraParams);
            propsToUpdate.forEach(propName => {
                const propValue = extraParams[propName];
                if (propValue) { 
                    filtersQuery+= `${isFirstProp ? '?' : '&'}${propName}=${propValue}`
                } else if (typeof propValue == "boolean") {
                    filtersQuery+= `${isFirstProp ? '?' : '&'}${propName}=${propValue}`
                }
                isFirstProp = false;
            })
        }
        



        return ApiService.get<Array<FormModel>>(this.apiController, 
            'combo' + filtersQuery
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}