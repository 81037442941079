import OptionSelect from "@/models/shared/OptionSelect"
import { translate } from "../plugins/i18n"

enum TransferStatus {
    Capture = 0
    , EndCapture
    , Approved
    , Cancelled
    , Precommitted
    , Committed
}

/**
 * Obtiene los valores del enumerador
 */
const possibleValues = Object.values(TransferStatus)
    .filter((v) => !isNaN(Number(v)))

function getOptions(): OptionSelect[] {
    return [
     {
            id: TransferStatus.Capture
            , name: translate("ENUMS.TRANSFER_STATUS.Capture")
        },
         {
            id: TransferStatus.EndCapture
            , name: translate("ENUMS.TRANSFER_STATUS.EndCapture")
        },
         {
            id: TransferStatus.Approved
            , name: translate("ENUMS.TRANSFER_STATUS.Approved")
        },
         {
            id: TransferStatus.Cancelled
            , name: translate("ENUMS.TRANSFER_STATUS.Cancelled")
        },
        {
            id: TransferStatus.Precommitted
            , name: translate("ENUMS.TRANSFER_STATUS.Precommitted")
        },
         {
            id: TransferStatus.Committed
            , name: translate("ENUMS.TRANSFER_STATUS.Committed")
        }
    ]
}
export { possibleValues, getOptions , TransferStatus}